import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>With his open, correct and pleasant manner, Mr. Six is universally appreciated and enables a goal-oriented and successful collaboration at all levels.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Tiia Markkanen </span> - COO, JDMT Medical Services AG</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Mr. Six is an extremely responsible, diligent and reliable worker who, thanks to his empathy and service-oriented behaviour is highly valued by all.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Axel Menzel </span> - Country HR Lead, Just Eat Takeaway.com</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel> 
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>He is just a go getter and when he says things are going to be done they get done. His attitude towards clients is always courteous, friendly and correct.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Adrien Zaradez </span> - Founder, studentity</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Mr. Six has a great deal of expertise. Thanks to his exemplary organisational talent and planning skills, he achieves permament optimal solutions.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Fabio Alini </span> - HR, JDMT Medical Services AG</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Lion always shows great commitment and self-motivation. He is just all around a great guy and I always appreciate our collaborations.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Pietro Brusoni </span> - Colleague, KSH Zurich </h6>
                                    </div>
                                </div>
                            </TabPanel>
{/*
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Amar Orthi </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Fatima Ma </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                                    </div>
                                </div>
                            </TabPanel>
*/}
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/client/testimonial-1.jpg"} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/client/testimonial-2.jpg"} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/client/testimonial-3.jpg"} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/client/testimonial-4.jpg"} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/client/testimonial-5.jpg"} alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                {/*
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
*/}
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;