import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { FaPython, FaReact, FaUnity } from "react-icons/fa";
import {SiBlender} from "react-icons/si";

const ServiceList = [
    {
        icon: <FaPython/>,
        title: 'Backend',
        description: 'I have completed various backend-heavy projects with Python as well as C++, Java and PowerShell.'
    },
    {
        icon: <FaReact />,
        title: 'Frontend',
        description: 'Numerous web applications have been created and deployed with React, CSS, HTML, JavaScript, Next.js, PHP and Typescript.'
    },
    {
        icon: <FaUnity />,
        title: 'Game development',
        description: 'I love creating games using Unity, C# and python. I have a lot of experience with deploying and managing mobile applications.'
    },
    {
        icon: <SiBlender />,
        title: 'Creative',
        description: 'I can comfortably produce 3D, 2D and most other creative assets with Blender, Gimp and Davinci Resolve.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'Running ad campaigns on all major target platforms in addition to extensive user behaviour analysis.'
    },
    {
        icon: <FiLayers />,
        title: 'Business & Finance',
        description: 'I have a business & finance background and can cover anything from amortization to exotic stock options. '
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="https://github.com/snow2405" taget="_blank">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
